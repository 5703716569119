import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

const styles = {
  dial: {
    display: "inline-block",
    width: `300px`,
    height: `auto`,
    color: "#000",
    // border: "0.5px solid #fff",
    padding: "2px"
  },
  title: {
    fontSize: "1em",
    color: "#000",
    textAlign: 'center'
  }
};

const Speedometer = ({ id, value, title, maxValue, minValue }) => {

  function segmentValueFormatter(value) {
    return `${value} K`
  }

  return (
    <div style={styles.dial}>
      <ReactSpeedometer
        maxValue={maxValue}
        minValue={minValue}
        height={190}
        width={290}
        value={value}
        needleTransition="easeQuadIn"
        needleTransitionDuration={1000}
        needleColor="navyBlue"
        startColor="red"
        segments={4}
        endColor="green"
        currentValueText={`${value} K`}
        segmentValueFormatter={segmentValueFormatter}
      />
      <div style={styles.title}>{title}</div>
    </div>
  );
};

export default Speedometer;
