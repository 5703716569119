/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  heroContainer: {
    bg: `white`,
    // background: `linear-gradient(145deg,#e0e9fa,#593e31)`,
    pt: [6, 6],
    pb: [4, 4],
    px: [4, 0]
  },
  contentTwoContainer: {
    bg: `omegaDarker`,
    borderRadius: `xl`,
    py: [4, 5],
    px: [4, 0]
  },
  formContainer: {
    bg: `white`,
    px: [4, 0]
  },
  socialProofContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      width: `100%`,
      height: `100%`,
      position: `absolute`,
      top: 0,
      left: 0,
      zIndex: -2,
      bg: `#F6F7FA`
    }
  }
}
